/** @jsx jsx */
import { jsx, Box, Button, Container, Flex, Styled } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Img from "gatsby-image"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { ColorBar, HeroGradient } from "../components/elements"

const FundraiseActivity = ({ data, pageContext }) => {
  const page = data.datoCmsFundraiseActivity
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Box>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
          </BackgroundImage>
        </Hero>

        <Container sx={{ px: "1em", py: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Box
              as="main"
              sx={{
                flexGrow: 99999,
                flexBasis: 0,
                minWidth: 320,
              }}
            >
              {/* Content */}
              <Box as="section" id="content">
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    mx: -4,
                    py: 4,
                  }}
                >
                  {page.eventIdeas.map(({ ...event }) => (
                    <Flex
                      key={event.id}
                      sx={{
                        flexWrap: "wrap",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        px: 4,
                        py: 4,
                        textAlign: "center",
                        width: ["full", "full", "33.333%"],
                      }}
                    >
                      <Img
                        fluid={event.eventImage.fluid}
                        alt={`${event.eventName} Image`}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        sx={{
                          borderRadius: "full",
                          mx: ["auto"],
                          my: 3,
                          height: 140,
                          width: 140,
                        }}
                      />

                      <Box sx={{ width: "full" }}>
                        <Box as="h3" sx={{ mb: 2 }}>
                          {event.eventName}
                        </Box>
                        <Box as="p" sx={{ mb: 3 }}>
                          {event.eventDescription}
                        </Box>
                      </Box>

                      <Box sx={{ width: "full" }}>
                        <a href="/giving/fundraise-your-way/create-fundraiser">
                          <Button as="span">Create Fundraiser</Button>
                        </a>
                      </Box>
                    </Flex>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default FundraiseActivity

export const query = graphql`
  query fundraiseActivityQuery($slug: String!) {
    datoCmsFundraiseActivity(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      eventIdeas {
        id
        eventName
        eventDescription
        eventImage {
          fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      id
      title
      slug
      meta {
        status
      }
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`
